// @flow
import layoutStyles from '../layout.module.css';
import tableStyles from './table.module.css';
import { withStyles } from '@material-ui/core/styles';
import {
  AutoSizer,
  ScrollSync,
} from 'react-virtualized';
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { scrollbarSize } from 'dom-helpers';
import classNames from 'classnames';
import React from 'react';
import {
    Tooltip,
    ClickAwayListener,
} from '@material-ui/core';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

import InnerContent from './InnerContent';
import CustomCheckBox from '../components/CheckBox';

import getSafe from './hooks/helpers';
import useLot from './hooks/useLot';

import Column, { columnIds } from './utils/Column';
import {
  awardColumns,
  awardValueColumn,
  AWARD_COLUMN_IDS
} from './utils/award';

import 'react-virtualized/styles.css';
import './index.css';
import updateLotAward from './services/updateLotAward';
import getNumberFromString from './hooks/helpers/getNumber';
import { assignBestBidAtLot } from './hooks/helpers/getFormattedSupplierResponses';
import getGreenHighlightColumn from './hooks/helpers/getGreenHighlightColumn';

const state = {
  overscanColumnCount: 10,
  overscanRowCount: 10,
  rowHeight: 27,
  rowCount: 100,
};

const FormulaTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgb(0, 0, 0)',
    boxShadow: theme.shadows[1],
    fontSize: 16,
    fontWeight: 500,
    fontFamily: 'Selawik',
    paddingLeft: 10,
    paddingRight: 10
  },
}))(Tooltip);

const Formula = ({ formula }) => {
  const [open, setOpen] = useState(false);
  const handleTooltipClose = () => setOpen(false)
  const handleTooltipOpen = () => setOpen(true)
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <FormulaTooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={formula}
        className={tableStyles.formulaTooltip}
      >
        <span
          className={classNames(
            tableStyles.suffix,
            tableStyles.link
          )}
          onClick={handleTooltipOpen}
        >
          (f)
        </span>
      </FormulaTooltip>
    </ClickAwayListener>
  )
}

const DEFAULT_AWARD_BY = 'percentage';

function App (props: any): React$Element<any>|null {

  const {
    award,
    baselineTotal,
    currencySymbol,
    eventId,
    eventSavingsTotal,
    hiddenColumns,
    hideSuppliers,
    lotId,
    localeCode,
    optimizedAt,
    filterValue,
    isLoading,
    baseUrl,
    subDomain,
    scenarioId,
    accessToken
  } = props;

  const isOptimizedAtLot = optimizedAt === 'lot';

  const awardRef = useRef();
  const baseUrlRef = useRef();
  const dataRef = useRef();
  const eventRef = useRef();
  const localeRef = useRef();
  const lotRef = useRef();
  const sharesRef = useRef();
  const optimizedRef = useRef();
  const subDomainRef = useRef();
  const scenarioIdRef = useRef();

  const formatValue = (value, decimalSpaces) => {
    const formatter = new Intl.NumberFormat(localeRef.current, {
      maximumFractionDigits: decimalSpaces,
      minimumFractionDigits: decimalSpaces
    });
    return formatter.format(value);
  };

  const [data, setData] = useLot({
    eventId,
    lotId,
    localeRef,
    subDomain,
    baseUrl,
    scenarioId,
    accessToken
  });

  const EXCLUDED_FIELD = 'checkbox_value';
  const IGNORED_FIELD = 'baseline_checkbox_value';

  const defaultShares = {
    lotId: null,
    lot_level_shares: {},
    line_item_level_shares: {},
    [EXCLUDED_FIELD]: [],
    [IGNORED_FIELD]: []
  };

  const [shares, setShares] = useState({
    ...defaultShares
  });

  const getLineShares = (optimizedAt, shares) => {
    return optimizedAt === 'line' ? shares.line_item_level_shares : {};
  }

  const getLotShares = (optimizedAt, shares) => {
    return optimizedAt === 'lot' ? shares.lot_level_shares : {};
  }

  useEffect(() => {
    awardRef.current = award;
    baseUrlRef.current = baseUrl;
    dataRef.current = data;
    eventRef.current = eventId;
    lotRef.current = lotId;
    optimizedRef.current = optimizedAt;
    sharesRef.current = shares;
    subDomainRef.current = subDomain;
    scenarioIdRef.current = scenarioId
  }, [
    award,
    baseUrl,
    data,
    eventId,
    lotId,
    optimizedAt,
    shares,
    subDomain,
    scenarioId
  ]);

  const [errors, setErrors] = useState({
    lot: false,
    line: []
  });

  useEffect(() => {
    if (!award) {
      return;
    }
    const oldLotId = shares.lotId;
    const newAward: any = awardRef.current;
    const newLotId = newAward.lot_id;
    if (newLotId === oldLotId) {
      return;
    }
    if (newAward.errors) {
      setErrors(newAward.errors);
    }
    setShares({
      lotId: newLotId,
      lot_level_shares: newAward.lot_level_shares,
      line_item_level_shares: newAward.line_item_level_shares,
      [EXCLUDED_FIELD]: newAward[EXCLUDED_FIELD],
      [IGNORED_FIELD]: newAward[IGNORED_FIELD]
    });
  }, [award]);

  useEffect(() => {
    // ===========================
    // award related API call is
    // happening, reset everything
    // ===========================
    if (isLoading) {
      // ============
      // reset errors
      // ============
      setErrors({
        lot: false,
        line: []
      });
      // ============
      // reset shares
      // ============
      setShares({
        ...defaultShares,
        lotId
      });
    }
  }, [isLoading]);

  const lineErrorSet: Set<string> = useMemo(() => {
    return new Set((errors.line: any));
  }, [errors.line]);

  async function onUpdate (e) {
    // =========================
    // award particular lot
    // or line item to suppliers
    // =========================
    console.log('onUpdate: ', lotRef.current)
    const currentAwardBy = getSafe(() => (awardRef.current : any).award_by);
    const currentShares = sharesRef.current || {};
    const optimizedAt = optimizedRef.current;
    const lineItemShares = getLineShares(optimizedAt, currentShares);
    const lotShares = getLotShares(optimizedAt, currentShares);
    const optimizedAtLevel = optimizedRef.current === 'lot' ? 'lot_level' : 'line_item_level';
    let ignoredValues = currentShares[IGNORED_FIELD];
    let excludedValues = currentShares[EXCLUDED_FIELD];
    const currentData = dataRef ? dataRef.current : null;
    if (currentData !== null && currentData !== undefined) {
      const excludedSet = new Set(excludedValues);
      const ignoredSet = new Set(ignoredValues);
      const { lineItems } = currentData;
      lineItems.forEach(row => {
        if (row.is_empty_row) {
          excludedSet.add(row.urid);
          ignoredSet.add(row.urid);
        }
      })
      excludedValues = Array.from(excludedSet);
      ignoredValues = Array.from(ignoredSet);
    }
    const payload = {
      award_by: currentAwardBy || DEFAULT_AWARD_BY,
      [IGNORED_FIELD]: ignoredValues,
      [EXCLUDED_FIELD]: excludedValues,
      eventId: eventRef.current,
      line_item_level_shares: lineItemShares,
      lot_id: lotRef.current,
      lot_level_shares: lotShares,
      optimized_at: optimizedAtLevel,
    };
    const serverOptions = {
      subDomain: subDomainRef.current,
      baseUrl: baseUrlRef.current,
      accessToken
    };
    const result = await updateLotAward(payload, serverOptions, scenarioIdRef.current);
    if (result && result.errors) {
      const currentLotErrors = result.errors.filter(e => e.lot_id === lotRef.current);
      if (optimizedRef.current === 'lot') {
        setErrors({
          line: [],
          lot: true
        });
      }
      else {
        setErrors({
          line: currentLotErrors.map(e => e.urid),
          lot: false
        });
      }
    }
    else {
      setErrors({
        line: [],
        lot: false
      });
    }
    if (
      result
      && result.data
      && result.data.supplier_responses
    ) {
      console.log('result: ', result)
      const {
        data: {
          supplier_responses: newResponses
        }
      } = result;

      const formatValue = (value, decimalSpaces) => {
        const formatter = new Intl.NumberFormat(localeRef.current, {
          maximumFractionDigits: decimalSpaces,
          minimumFractionDigits: decimalSpaces
        });
        return formatter.format(value);
      };
      
      setData(data => {
        const responses = {
          ...data.responses
        };
        const {
          normalSupplierColumns,
          alternateSupplierColumns
        } = data;
        const decimalMap = new Map(
          [
            ...normalSupplierColumns.map(c => [c.ucid, c.decimal_spaces]),
            ...alternateSupplierColumns.map(c => [c.ucid, c.decimal_spaces])
          ]
        );
        Object.keys(responses).forEach(supplierId => {
          const response = responses[supplierId];
          const serverSentResponse = newResponses[supplierId];
          if (!supplierId || !(serverSentResponse && serverSentResponse.totals_row)) {
            return;
          }
          const { totals_row } = serverSentResponse;
          if (!(totals_row && typeof totals_row === 'object')) {
            return;
          }
          Object.keys(totals_row).forEach(columnId => {
            const rawValue = totals_row[columnId];
            if (!(
              typeof rawValue === 'number'
              || typeof rawValue === 'string'
            )) {
              return;
            }
            if (!decimalMap.has(columnId)) {
              return;
            }
            const convertedValue = getNumberFromString(rawValue);
            totals_row[columnId] = formatValue(convertedValue, decimalMap.get(columnId));
          })
          response.totals_row = {
            ...response.totals_row,
            ...totals_row
          };
          const greenHighlightColumnId = getGreenHighlightColumn(data);
          if (!decimalMap.has(greenHighlightColumnId)) {
            return;
          }
          const decimalSpaces = decimalMap.get(greenHighlightColumnId);
          const bestBid = getNumberFromString(
            formatValue(result.data.lot_event_saving.total, decimalSpaces)
          );
          const updatedResponse = assignBestBidAtLot(
            bestBid,
            data.column_info,
            formatValue,
            greenHighlightColumnId,
            response
          );
          responses[supplierId] = updatedResponse;
        });
        return {
          ...data,
          responses
        }
      });
    }
  }

  const isAwardDraft = getSafe(() => award.isDraft);
  const lineItemShares = getLineShares(optimizedAt, shares);
 
  const AWARD_BY = {
    'quantity': 'Qty',
    'percentage': '%'
  };

  const awardBy = getSafe(() => AWARD_BY[award.award_by || DEFAULT_AWARD_BY]);

  const [headerHeight, setHeaderHeight] = useState({
    supplierNameHeight: 43,
    termNameHeight: 27,
    totalsHeight: 27
  });

  const [hideHorizontalScroll, setHideHorizontalScroll] = useState(false);

  const contentGrid = useRef();
  const dummyContentGrid = useRef();

  const setContentRef = ref => contentGrid.current = ref;
  const setDummyContentRef = ref => dummyContentGrid.current = ref;

  const { buyerColumns: columnsFilledByBuyer } = data;
  const buyerColumns = columnsFilledByBuyer;
  const hiddenColumnSet = new Set(hiddenColumns);

  useEffect(() => {
    localeRef.current = localeCode;
  }, [localeCode]);

  const {
    suppliersOrder,
    suppliers,
    normalSupplierColumns,
    alternateSupplierColumns,
    lineItems,
    responses,
    totals_row,
    buyer_totals,
    surrogate_bidders_data,
    isTransformationAppliedAtLineItemLevel,
    bidAction
  } = data;

  const sizeOfScroll = scrollbarSize();

  const getHeaderHeight = useCallback(() => {
    return (
      headerHeight.supplierNameHeight
      + headerHeight.termNameHeight
      + headerHeight.totalsHeight
    );
  }, [headerHeight])

  const totalTableHeight = useMemo(() => {
    const { lineItems } = data;
    const showLineItemsWithNoResponse = filterValue === 'lineItemWithNoRes';
    const visibleLineItems = lineItems.filter(item => !showLineItemsWithNoResponse || item.is_empty_row)
    const visibleLineItemsLength = Math.min(visibleLineItems.length, 12);
    return (
      getHeaderHeight()
      + state.rowHeight * visibleLineItemsLength
      + (hideHorizontalScroll ? 0 : sizeOfScroll)
    )
  }, [
    data,
    filterValue,
    sizeOfScroll,
    hideHorizontalScroll,
    getHeaderHeight
  ]);

  const showLineItemsWithNoResponse = filterValue === 'lineItemWithNoRes';

  const getSupplierColumn = (index) => {
    const supplierIndex = index - buyerColumns.length;
    const supplierId = suppliersOrder[supplierIndex];
    const supplierColumn = new Column(suppliers[supplierId])
    return supplierColumn
  }

  const visibleFilter = (columns: any[]): any[] => {
    return (
      columns.filter(c => !hiddenColumnSet.has(c.ucid))
    )
  };

  const getColumnWidthHelper = ({ index }) => {
    const FIXED_COLUMN_WIDTHS = {
      [AWARD_COLUMN_IDS.excluded]: 100,
      [AWARD_COLUMN_IDS.ignored]: 70,
      sr_no: 70,
      item_name: 180,
      quantity: 81,
      baseline_price: 135,
      event_saving: 135,
      award_savings: 135,
      uom: 75
    };
    const buyerColumn = getColumnInstance(0, index);
    if (hiddenColumns && hiddenColumns.includes(buyerColumn.ucid)) {
      return 0;
    }
    if (buyerColumn.ucid === 'quantity' && bidAction === 'e') {
      return 130;
    }
    if (buyerColumn.ucid && buyerColumn.ucid in FIXED_COLUMN_WIDTHS) {
      return FIXED_COLUMN_WIDTHS[buyerColumn.ucid];
    }
    const supplierColumn = buyerColumn;
    const supplier_id = supplierColumn.safeAccess('supplier_id');
    if (supplier_id in hideSuppliers) {
      return 0;
    }
    if (
      supplierColumn.isValid
      && !supplierColumn.isBuyerFilled
    ) {
      // ===========================
      // supplier section to render
      // calculate all subcolumns
      // and interpolate width based 
      // on total number of columns
      // ===========================
      const additionalWidth = isAwardDraft ? 135 : 0;
      const columns = (
        supplierColumn.isAlternateBid
        ? alternateSupplierColumns
        : normalSupplierColumns
      );
      if (columns.length === 1 && !isAwardDraft) {
        return 180;
      }
      return visibleFilter(columns).length * 135 + additionalWidth;
    }
    return 150;
  }

  function recomputeGrid() {
    const recompute = ref => ref.current ? ref.current.recomputeGridSize() : {};
    recompute(contentGrid);
    recompute(dummyContentGrid);
  }

  function setUpdatedHeights(result) {
    setHeaderHeight(heights => ({
      ...heights,
      ...result
    }));
  }

  // =======================================
  // To adjust the header height in order to
  // accomodate changes in different column
  // content height
  // 
  // E.g.
  // In award scenario, there is a
  // column Ignore Spend which requires
  // more height to render all content
  // =======================================
  const measureHeader = () => {
    const cellContainers = document.querySelectorAll(`.dummyParent`);
    if (cellContainers.length) {
      const result = {
        supplierNameHeight: 43,
        termNameHeight: 27
      };
      const getHeight = (elem: HTMLElement) => elem.getBoundingClientRect().height;
      for(let i = 0; i < cellContainers.length; i++) {
        const currentContainer = cellContainers[i];
        const supplierNameDiv = ((currentContainer.querySelector('div:nth-child(1)'): any) : HTMLElement);
        result.supplierNameHeight = Math.max(result.supplierNameHeight, getHeight(supplierNameDiv));
        const termNameDiv = ((currentContainer.querySelector('div:nth-child(2)'): any): HTMLElement);
        result.termNameHeight = Math.max(result.termNameHeight, getHeight(termNameDiv));
      }
      const isAwardDraft = award && award.isDraft;
      if (isAwardDraft && result.supplierNameHeight < 72) {
        result.supplierNameHeight = 72;
      }
      const needsToUpdate = (
        result.supplierNameHeight !== headerHeight.supplierNameHeight
        || result.termNameHeight !== headerHeight.termNameHeight
        );
      if (needsToUpdate) {
        setUpdatedHeights(result);
      }
    }
  };

  const getExcludeIgnoredArray = fieldName => new Set(shares ? shares[fieldName] : []);

  const excludedSet = useMemo(
    () => {
      const excludedSetInterim = getExcludeIgnoredArray(EXCLUDED_FIELD);
      if (Array.isArray(lineItems)) {
        lineItems.forEach(row => {
          if (row.is_empty_row) {
            excludedSetInterim.add(row.urid);
          }
        })
      }
      return excludedSetInterim;
    }
  , [shares[EXCLUDED_FIELD], lineItems]);

  const ignoredSet = useMemo(
    () => {
      const ignoredSetInterim = getExcludeIgnoredArray(IGNORED_FIELD);
      if (Array.isArray(lineItems)) {
        lineItems.forEach(row => {
          if (row.is_empty_row) {
            ignoredSetInterim.add(row.urid);
          }
        })
      }
      return ignoredSetInterim;
    }
  , [shares[IGNORED_FIELD], lineItems]);

  useEffect(() => {
    setTimeout(() => {
      // =========================
      // wait 1 second for DOM to
      // reflect the new height in
      // dummy container
      // =========================
      measureHeader();
    }, 1000);
  }, [
    hiddenColumns,
    hideSuppliers,
    suppliers,
    award
  ]);

  useEffect(() => {
    recomputeGrid();
  }, [
    hiddenColumns,
    hideSuppliers,
    filterValue,
    headerHeight,
    data,
    award
  ]);

  const getSupplierTotals = (supplierId, columnData) => {
    if (filterValue === 'lineItemWithNoRes') {
      return ''
    }
    const column = new Column(columnData);
    const { transformation_costs_data } = data;
    if (
      column.isPTF
      && !data.isTransformationAppliedAtLineItemLevel
    ) {
        const supplier_id = supplierId.toString().split('_')[0];
        return getSafe(() => transformation_costs_data[supplier_id][columnData.ucid]);
    }
    if (column.isPTF && data.isTransformationAppliedAtLineItemLevel) {
      return '';
    }
    return getSafe(() => responses[supplierId]['totals_row'][columnData.ucid]) || '';
  }

  const getGreenHighlightClassForLot = (supplierId, column) => {
    if (filterValue === 'lineItemWithNoRes') {
      return null;
    }
    if (isLotExcluded) {
      return null;
    }
    const greenHighlightAccessor = () => responses[supplierId].totals_row.greenHighlight;
    if (
      optimizedAt === 'lot'
      && getSafe(greenHighlightAccessor)
      && getSafe(greenHighlightAccessor) === column.ucid
    ) {
      return tableStyles.bestBid;
    }
    return null;
  }

  const isLotExcluded = Array.isArray(lineItems) && excludedSet.size === lineItems.length;
  const isLotIgnored = Array.isArray(lineItems) && excludedSet.size > 0 && ignoredSet.size === excludedSet.size;

  const getAwardSavingAtLine = (urid) => {
    if (
      award
      && award.line_item_award_saving
      && award.line_item_award_saving[urid]
    ) {
      return award.line_item_award_saving[urid];
    }
    return 0;
  }

  const awardSavingTotal = useMemo(() => {
    const { lineItems } = data;
    if (isOptimizedAtLot) {
      return (
        (award && award.lot_award_saving)
        ? formatValue(award.lot_award_saving, 2)
        : ''
      );
    }
    else {
      if (Array.isArray(lineItems)) {
        const totalAwardSaving = lineItems.reduce((acc, lineItem) => acc + getAwardSavingAtLine(lineItem.urid), 0);
        return formatValue(totalAwardSaving, 2);
      }
      return '';
    }
  }, [award, data, isOptimizedAtLot]);

  const eventSavingTotal = useMemo(() => {
    return 1;
  }, [isOptimizedAtLot])

  const awardColumnStyle = useMemo(() => {
    const awardStyle = {
      width: 117 - (awardBy === '%' ? 11 : 0),
      textAlign: 'right',
      paddingRight: 5
    };
    return awardStyle;
  }, [awardBy]);

  const makeHeaderRenderer = (isDummy=false) => {
    const _headerRenderer = ({
      columnIndex,
      key,
      style
    }) => {
      const {
        buyerColumns: columns,
      } = data;
      const buyerColumn = getColumnInstance(0, columnIndex);
      const supplierIndex = columnIndex - columns.length - (isAwardDraft ? 3 : 0);
      const supplierColumn = buyerColumn;
      const supplierId = supplierColumn.safeAccess('supplier_id');
      const fixHeight = rowName => !isDummy ? {
        style: {
          height: headerHeight[rowName]
        }
      } : {}
      const supplierNameStyles = fixHeight('supplierNameHeight');
      const termNameStyles = fixHeight('termNameHeight');
      const totalsStyles = fixHeight('totalsHeight');
      const subColumns = supplierColumn.columns.slice();
      if (isAwardDraft) {
        subColumns.push(
          awardValueColumn({
            awardBy,
            isAwardDraft,
            optimizedAt
          })
        );
      }

      const getLotShare = (supplierId) => {
        if (isLotExcluded) {
          return '';
        }
        if (optimizedAt === 'line') {
          return '-';
        }
        return getSafe(() => shares.lot_level_shares[supplierId]) || '';
      }

      const changeLotShare = e => {
        const supplierId = e.target.getAttribute('data-supplier');
        const value = e.target.value;
        if (isNaN(value)) {
          return;
        }
        let parsedValue = parseInt(value, 10);
        if (awardBy === '%') {
          parsedValue = Math.min(100, parsedValue);
        }
        const lot_level_shares = { ...shares.lot_level_shares };
        if (isNaN(parsedValue)) {
          delete lot_level_shares[supplierId];
        }
        else {
          lot_level_shares[supplierId] = parsedValue;
        }
        setShares(shares => {
          return ({
            ...shares,
            lot_level_shares
          })
        });
      };

      const showError = isAwardDraft && optimizedAt === 'lot' && errors && errors.lot === true;
      const supplierName = getSafe(() => suppliers[suppliersOrder[supplierIndex]].supplier_name);
      let trimmedSupplierName = supplierName;
      if (supplierName && supplierName.length > 17) {
        trimmedSupplierName = supplierName.slice(0, 17) + '...';
      }

      return (
        <div
          key={key}
          style={{
            ...style,
          }}
          className={classNames(tableStyles.headerCell)}
        >
          {
            buyerColumn.isBuyerFilled ? (
              <div className={classNames(
                  layoutStyles.flex,
                  layoutStyles.col
                )}>
                <div className={classNames(
                    tableStyles.row,
                    { [tableStyles.supplierName]: !isDummy },
                    tableStyles.borderTop,
                    tableStyles.borderRight
                  )}
                  style={{
                    height: headerHeight.supplierNameHeight
                  }}
                >
                  <div>
                    {buyerColumn.termName}
                  </div>
                  <div>
                    {
                      buyerColumn.ucid === AWARD_COLUMN_IDS.ignored && (
                        <FormulaTooltip
                          title="Checked line items will not count towards Baseline Spend and Event Savings"
                          placement="top"
                        >
                          <InfoOutlined />
                        </FormulaTooltip>
                      )
                    }
                  </div>
                </div>
                <div className={classNames(
                    tableStyles.row,
                    tableStyles.borderRight,
                  )}
                  style={{
                    height: headerHeight.termNameHeight
                  }}
                  >
                  {
                    buyerColumn.ucid === AWARD_COLUMN_IDS.excluded
                    ? (
                      <CustomCheckBox
                        checked={isLotExcluded}
                        onChange={onExcludeAllChange}
                      />
                    ) : buyerColumn.ucid === AWARD_COLUMN_IDS.ignored
                    && (
                      <CustomCheckBox
                        checked={isLotIgnored}
                        onChange={onIgnoreAllChange}
                      />
                    )
                  }
                </div>
                <div className={classNames(
                    tableStyles.row,
                    tableStyles.borderTop,
                    tableStyles.borderRight,
                    tableStyles.totalCell,
                    {
                      'error-cell': showError
                    }
                  )}
                  style={{
                    height: headerHeight.totalsHeight
                  }}
                >
                  {
                    buyerColumn.showTotal && (
                      <span style={{
                        display: 'flex',
                        justifyContent: buyerColumn.justifyContent
                      }}>
                        <span>
                          {
                            buyerColumn.isPrice && currencySymbol
                          }
                        </span>
                        <span>
                          {
                            buyerColumn.ucid === 'baseline_price' ?
                            baselineTotal : 
                            buyerColumn.ucid === 'event_saving' ?
                            eventSavingsTotal :
                            buyerColumn.ucid === 'award_savings' ?
                            awardSavingTotal :
                            totals_row[buyerColumn.ucid]
                          }
                        </span>
                        {
                          buyerColumn.showTotal
                          && buyerColumn.isPercentage
                          && (
                            <span className={tableStyles.suffix}>
                              %
                            </span>
                          )
                        }
                      </span>
                    )
                  }
                </div>
              </div>
            ) : (
                <div className={classNames(
                  tableStyles.borderTop,
                  { 'dummyParent': isDummy }
                )}>
                  <div
                    className={classNames({
                      [tableStyles.supplierName]: !isDummy,
                      [tableStyles.pad5]: isDummy,
                      [tableStyles.borderRight]: true
                    })
                    }
                    {...supplierNameStyles}
                  >
                    <div
                      className={classNames({
                        'dummySupplierName': isDummy
                      })}
                      data-test="supplier-name"
                      title={supplierName}
                    >
                      {trimmedSupplierName}
                      {supplierColumn.isAlternateBid && ' (Alternate Bid)'}
                      {
                        getSafe(() => surrogate_bidders_data[supplierId].updated_by) === 'Surrogate Bidder' &&
                          <Fragment>
                            <br />
                            Surrogate response by {
                              getSafe(() => surrogate_bidders_data[supplierId].updated_by_name)
                            }
                          </Fragment>
                      }
                    </div>
                  </div>
                  <div
                    className={classNames(
                      layoutStyles.flex,
                      layoutStyles.row,
                      { [tableStyles.row]: !isDummy },
                      tableStyles.borderBottom,
                      tableStyles.borderTop,
                      tableStyles.contentCenter,
                      tableStyles.pad0,
                      tableStyles.headerNames
                    )}
                    {...termNameStyles}
                  >
                    {
                      subColumns
                        .filter(({ ucid }) => !hiddenColumnSet.has(ucid))
                        .map((subColumn, key) => (
                        <div
                          key={key}
                          className={classNames(
                            layoutStyles.flex1,
                            layoutStyles.flex,
                            tableStyles.borderRight,
                            tableStyles.contentCenter,
                            tableStyles.padCell
                          )}>
                            <div className={classNames(
                              layoutStyles.flex
                            )}>
                              <span>
                                {subColumn.term_name}
                              </span>
                              {
                                subColumn.who_fills === 'Formula' && (
                                  <Formula {...subColumn} />
                                )
                              }
                            </div>
                        </div>
                      ))
                    }
                  </div>
                  <div
                    data-test="totals"
                    className={classNames(
                      layoutStyles.flex,
                      layoutStyles.row,
                      tableStyles.row,
                      tableStyles.borderBottom,
                      tableStyles.contentCenter,
                      tableStyles.pad0,
                      {
                        'error-cell': showError
                      }
                    )}
                    {...totalsStyles}
                  >
                    {
                      subColumns
                        .filter(({ ucid }) => !hiddenColumnSet.has(ucid))
                        .map((subColumnData, key) => {
                          const subColumn = new Column(subColumnData);
                          const isAwardColumn = subColumn.ucid === 'share';
                          const supplierTotal = (
                            subColumn.showTotal
                            && !subColumn.isEditable 
                            && getSupplierTotals(supplierId, subColumnData)
                          );
                          const showValue = !isLotExcluded && (!subColumn.isPTF || (supplierTotal && supplierTotal.length > 0))
                          let hideValue = !showValue;
                          return (
                            <div className={classNames(
                              layoutStyles.flex1,
                              getGreenHighlightClassForLot(supplierId, subColumnData),
                              tableStyles.borderRight,
                              layoutStyles.flex,
                              tableStyles.contentCenter,
                              tableStyles.itemsCenter,
                              tableStyles.padCell,
                              tableStyles.fontSelawik,
                              {
                                [tableStyles.editable]: !isLotExcluded && subColumn.isEditable && subColumn.isLotOptimized,
                                excluded: isLotExcluded && subColumn.isEditable
                              }
                            )}
                              key={key}
                              style={{
                                justifyContent: subColumn.justifyContent
                              }}
                            >
                              <span>
                                {
                                  subColumn.showTotal
                                  && subColumn.isPrice
                                  && !hideValue
                                  && currencySymbol
                                }
                              </span>
                              <span
                                data-description="Total cell, shows input in case of lot level award draft"
                                data-ucid={subColumn.ucid}
                                className={layoutStyles.flex}
                              >
                                {
                                  subColumn.showTotal
                                  && !subColumn.isEditable
                                  && !hideValue
                                  ? getSupplierTotals(supplierId, subColumnData)
                                  : isAwardColumn
                                  ? (
                                    <input
                                      style={awardColumnStyle}
                                      value={getLotShare(supplierId)}
                                      onChange={changeLotShare}
                                      data-supplier={supplierId}
                                      className={tableStyles.awardInput}
                                      disabled={isLotExcluded}
                                    />
                                  ) : null
                                }
                                {
                                  !isLotExcluded
                                  && isAwardColumn
                                  && subColumn.isLotOptimized
                                  && awardBy === '%' ? '%' : null
                                }
                              </span>
                                {
                                  subColumn.showTotal
                                  && subColumn.isPercentage
                                  && (
                                    <span className={tableStyles.suffix}>
                                      %
                                    </span>
                                  )
                                }
                            </div>
                          )
                        })
                    }
                  </div>
                </div>
            )
          }
        </div>
      )
    };
    return _headerRenderer;
  }

  const _headerRenderer = makeHeaderRenderer();
  const _dummyHeaderRenderer = makeHeaderRenderer(true);

  const getEventSavings = (row) => {
    if (filterValue !== 'allLineItems' 
      || optimizedAt === 'lot'
      || row.is_empty_row
      || !buyer_totals.event_saving_at_line[row.urid]) {
      return '-'
    }
    return buyer_totals.event_saving_at_line[row.urid].event_saving
  }

  const columnCount = (isAwardDraft ? 3 : 0) + buyerColumns.length + suppliersOrder.length;

  if (!(data && data.buyerColumns && data.buyerColumns.length > 0)) {
    return null;
  }

  const getGreenHighlightClassForLineItem = (rowIndex, columnIndex, ucid) => {
    const supplierColumn = getColumnInstance(rowIndex, columnIndex);
    const supplierId = supplierColumn.safeAccess('supplier_id');
    const row = getSafe(() => lineItems[rowIndex]);
    const greenHighlightAccessor = () => responses[supplierId].row_data[row.urid].greenHighlight;
    if (optimizedAt !== 'lot'
      && getSafe(greenHighlightAccessor)
      && getSafe(greenHighlightAccessor) === ucid
    ) {
      return tableStyles.bestBid
    }
    return null
  }

  const getRowHeight = ({ index }) => {
    if (index === 0) {
      return getHeaderHeight();
    }
    const row = lineItems[index - 1];
    if (showLineItemsWithNoResponse && !row.is_empty_row) {
      return 0;
    }
    return 27;
  }

  const getColumnInstance = (rowIndex, originalColumnIndex) => {
    let columnIndex = originalColumnIndex;
    // =======================================
    // bid action - b - lot level bid
    //     - here two columns are not rendered
    //         1. quantity
    //         2. unit of measurement
    //     - hence total 6 buyer columns
    // bi action - a, c will have 8 buyer
    // columns
    // ========================================
    const AWARD_SAVINGS_INDEX = bidAction === 'b' ? 6 : 8;
    if (isAwardDraft) {
      if (originalColumnIndex < AWARD_SAVINGS_INDEX) {
        // ==============================
        // This is buyer filled column
        // only subtract excluded/ignored
        // two checkbox type columns
        // ==============================
        columnIndex -= 2;
      }
      else {
        // ===============================
        // This is some supplier column
        // subtract all award related 
        // columns which are total 3 in 
        // quantity two exclude/ignore and
        // one award savings column
        // ===============================
        columnIndex -= 3;
      }
    }
    if (isAwardDraft) {
      if (originalColumnIndex < 2) {
        // =============================
        // originalIndex = 0 -> excluded
        // originalIndex = 1 -> ignored
        // =============================
        return new Column(awardColumns[originalColumnIndex]);
      }
      if (originalColumnIndex === AWARD_SAVINGS_INDEX) {
        // ===========================
        // return award savings column
        // ===========================
        return new Column(awardColumns[2]);
      }
    }
    if (columnIndex < buyerColumns.length) {
      return new Column({
        ...buyerColumns[columnIndex],
        isAwardDraft,
        optimizedAt
      });
    }
    return getSupplierColumn(columnIndex);
  }

  const onExcludeIgnore = e => {
    const { id, checked } = e.target;
    const [
      _prefix,
      rowId,
      field
    ] = id.split('-');
    /**
     * This is a function which can toggle
     * some specific field name (ignored/excluded)
     * 
     *     @example
     *     if fieldName == 'ignored' and checked == (true/false) {
     *         simply add/remove rowId from
     *         that corresponding array
     *     }
     * 
     *     @example
     *     if fieldName == 'excluded' and checked == true {
     *         simply add rowId from excluded array
     *     }
     * 
     *     @example
     *     if fieldName == 'excluded' and checked == false {
     *         remove rowId from excluded array
     *         remove rowId from ignored as well
     *     }
     * 
     * @param {*} oldShares - current shares state
     * @param {*} fieldName - name of field to toggle
     * @returns {*} - modified state of shares to set
     */
    const createNewShares = (oldShares, fieldName) => {
      let excludedIgnoredRows;
      const accessorField: string = (
        (fieldName === 'excluded')
        ? EXCLUDED_FIELD
        : IGNORED_FIELD
      );
      const line_item_level_shares = {
        ...oldShares.line_item_level_shares
      };
      if (fieldName === 'excluded') {
        delete line_item_level_shares[rowId];
      }
      if (checked) {
        excludedIgnoredRows = ((oldShares[accessorField]: any) || []).concat(rowId);
      }
      else {
        excludedIgnoredRows = ((oldShares[accessorField]: any) || []).filter(urid => urid !== rowId);
      }
      return ({
        ...oldShares,
        line_item_level_shares,
        [accessorField]: excludedIgnoredRows
      });
    }
    setShares(shares => {
      if (checked === true || field === 'ignored') {
        // =====================
        // if checked or ignored
        // go with normal flow
        // =====================
        return createNewShares(shares, field);
      }
      else {
        // ========================
        // if excluded is unchecked
        // also remove ignored
        // ========================
        return createNewShares(
          createNewShares(shares, field),
          'ignored'
        );
      }
    });
  }

  const onExcludeAllChange = e => {
    const { checked } = e.target;
    if (!checked) {
      // ==================================
      // uncheck exlude all line items
      // we need to uncheck ignored as well
      // ================================== 
      setShares(shares => ({
        ...shares,
        [EXCLUDED_FIELD]: [],
        [IGNORED_FIELD]: [],
      }));
    }
    else {
      // ======================
      // Exclude all line items
      // remove all line shares
      // ======================
      const checkbox_value = data.lineItems.map(l => l.urid);
      setShares(shares => ({
        ...shares,
        [EXCLUDED_FIELD]: checkbox_value,
        line_item_level_shares: {}
      }));
    }
  }

  const onIgnoreAllChange = e => {
    const { checked } = e.target;
    if (!checked) {
      // ==================================
      // uncheck exlude all line items
      // we need to uncheck ignored as well
      // ================================== 
      setShares(shares => ({
        ...shares,
        [IGNORED_FIELD]: [],
      }));
    }
    else {
      // ======================
      // Exclude all line items
      // remove all line shares
      // ======================
      setShares(shares => ({
        ...shares,
        [IGNORED_FIELD]: Array.from(excludedSet),
        line_item_level_shares: {}
      }));
    }
  }

  const getColumnValue = (rowIndex, columnIndex) => {
    if (rowIndex <= -1) {
      return null;
    }
    const lineItem = lineItems[rowIndex];
    const columnInstance = getColumnInstance(rowIndex, columnIndex);
    const isIgnored = ignoredSet.has(lineItem.urid);
    if (columnInstance.isBuyerFilled) {
      const buyerColumn = columnInstance;
      if (buyerColumn.ucid === 'sr_no') {
        return rowIndex + 1
      }

      const getIfExcluded = lineItem => {
        const { is_empty_row: isEmpty } = lineItem;
        const { urid: rowId } = lineItem;
        let isExcluded = false;
        if (rowId) {
          // ===================================================
          // convert urid to string because this comes as number
          // in case of bid action 3 while `checkbox_value` is
          // always stored in array of string in award object
          // ===================================================
          const rowIdInString = `${rowId}`;
          isExcluded = excludedSet.has(rowIdInString) || isEmpty || false;
        }
        return isExcluded;
      }

      const getIfIgnored = lineItem => {
        const { is_empty_row: isEmpty } = lineItem;
        const { urid: rowId } = lineItem;
        let isIgnored = false;
        if (rowId) {
          // ======================================================
          // convert urid to string because this comes as number
          // in case of bid action 3 while `baseline_checkbox_value`
          // is always stored in array of string in award object
          // =======================================================
          const rowIdInString = `${rowId}`;
          isIgnored = ignoredSet.has(rowIdInString) || isEmpty || false;
        }
        return isIgnored;
      }

      if (buyerColumn.ucid === AWARD_COLUMN_IDS.excluded) {
        // ============================
        // calculate if row is excluded
        // ============================
        const { is_empty_row: isEmpty } = lineItem;
        const isExcluded = getIfExcluded(lineItem);
        return (
          <CustomCheckBox
            checked={isExcluded}
            disabled={isEmpty}
            onChange={onExcludeIgnore}
            id={'row-' + lineItem.urid + '-excluded'}
          />
        );
      }
      if (buyerColumn.ucid === AWARD_COLUMN_IDS.ignored) {
        // ===========================
        // calculate if row is ignored
        // ===========================
        const isExcluded = getIfExcluded(lineItem);
        const { is_empty_row: isEmpty } = lineItem;
        const isIgnored = getIfIgnored(lineItem);
        return (
          <CustomCheckBox
            checked={isIgnored}
            disabled={!isExcluded || isEmpty}
            onChange={onExcludeIgnore}
            id={'row-' + lineItem.urid + '-ignored'}
          />
        );
      }
      
      const innerValue = getSafe(() => lineItem[buyerColumn.ucid]);
      let renderValues = true;
      let eventSavings = '';
      let awardSavings = '';
      const { ucid } = buyerColumn;
      if (
        isIgnored ||
        ucid === 'baseline_price'
        && lineItem.is_empty_row
        && !showLineItemsWithNoResponse
      ) {
        renderValues = false;
      }
      else if (!isIgnored && ucid === 'event_saving') {
        eventSavings = getEventSavings(lineItem);
        renderValues = typeof eventSavings === 'number' || typeof eventSavings === 'string' && !isNaN(eventSavings.replace(/,/g, ''));
      }
      else if (!isIgnored && ucid === AWARD_COLUMN_IDS.award_savings) {
        if (optimizedAt === 'lot') {
          renderValues = false;
        }
        else {
          renderValues = true;
          if (
            !isIgnored
            && award
            && award.line_item_award_saving
            && award.line_item_award_saving[lineItem.urid]
          ) {
            awardSavings = formatValue(award.line_item_award_saving[lineItem.urid], 2);
          }
        }
      }
      return (
        <span style={{
          display: 'flex',
          justifyContent: buyerColumn.justifyContent,
          height: '100%',
          overflow: 'hidden'
        }}>
          <span>
            {
              buyerColumn.isPrice
              && renderValues
              && currencySymbol
            }
          </span>
          {
            buyerColumn.type === 'Text' && (
              <span
                className={tableStyles.cellContent}
                dangerouslySetInnerHTML={{
                  __html: innerValue
                }}
                title={window.htmlToFormattedText(innerValue)}
              >
            </span>
            )
          }
          {
            buyerColumn.type !== 'Text' && (
              <span
                className={tableStyles.cellContent}
              >
                {
                  ucid === 'event_saving' ?
                  eventSavings :
                  ucid === 'baseline_price'
                  && !renderValues
                  ? '-' :
                  ucid === AWARD_COLUMN_IDS.award_savings ?
                  awardSavings
                  : getSafe(() => lineItem[ucid])
                }
            </span>
            )
          }
          {
            buyerColumn.isPercentage
            && (
              <span className={tableStyles.suffix}>
                %
              </span>
            )
          }
        </span>
      )
    }
    else {
      const { urid } = lineItem;
      const supplierColumn = getColumnInstance(rowIndex, columnIndex);
      const supplierId = supplierColumn.safeAccess('supplier_id');
      const supplierLineItem = getSafe(() => responses[supplierId].row_data[urid]);
      const columns = supplierColumn.columns.slice();
      if (isAwardDraft) {
        columns.push(
          awardValueColumn({
            awardBy,
            isAwardDraft,
            optimizedAt
          })
        );
      }
      return columns
        .filter(({ ucid }) => !hiddenColumnSet.has(ucid))
        .map(subColumn => {
          const column = new Column(subColumn);
          if (column.isPTF) {
              // ====================================
              // 1. ptf value per supplier, per rowId
              //    is received in the
              //    `transformation_costs_data` field
              // 2. actual transformed cost is not
              //    in this key, that is sent in
              //    `supplier_responses` key
              // =====================================
            const raw_supplier_id = supplierId.toString().split('_')[0];
            return {
              value: getSafe(() => data.transformation_costs_data[raw_supplier_id][subColumn.ucid]),
              ...subColumn
            }
          }
          return (supplierLineItem && {
            value: supplierLineItem[subColumn.ucid],
            ...subColumn
          }) || {}
      });
    }
  }

  const onSectionRendered = () => {
    const scrollContainer = ((document.querySelector('.ra-table [role="rowgroup"]'): any): HTMLElement);
    const getWidth = (elem: HTMLElement) => elem.getBoundingClientRect().width;
    const elemWidth = getWidth(scrollContainer);
    const parentWidth = getWidth((scrollContainer.parentElement: any));
    setHideHorizontalScroll(elemWidth < parentWidth);
  }

  if (isLoading) {
    return null;
  }

  return (
    <div className={tableStyles.table}>
      <button
        onClick={onUpdate}
        id="update-lot"
      />
      <ScrollSync>
      {
        ({
          onScroll,
        }) => {
          return (
            <AutoSizer disableHeight>
              {
                ({ width, }) => {
                  return(
                    <Fragment>
                      <div className="ra-table">
                        <InnerContent
                          {...state}
                          awardBy={awardBy}
                          awardColumnStyle={awardColumnStyle}
                          onScroll={onScroll}
                          rowCount={lineItems.length + 1}
                          height={totalTableHeight}
                          rowHeight={getRowHeight}
                          width={width}
                          data={responses}
                          lineItems={lineItems}
                          excludedSet={excludedSet}
                          ignoredSet={ignoredSet}
                          showLineItemsWithNoResponse={showLineItemsWithNoResponse}
                          columnCount={columnCount}
                          columnWidth={getColumnWidthHelper}
                          checkBestBid={getGreenHighlightClassForLineItem}
                          hiddenColumns={hiddenColumns}
                          setContentRef={setContentRef}
                          currencySymbol={currencySymbol}
                          lineErrorSet={lineErrorSet}
                          hideSuppliers={hideSuppliers}
                          _headerRenderer={_headerRenderer}
                          getColumnInstance={getColumnInstance}
                          getColumnValue={getColumnValue}
                          setShares={setShares}
                          isTransformationAppliedAtLineItemLevel={isTransformationAppliedAtLineItemLevel}
                          heights={headerHeight}
                          onSectionRendered={onSectionRendered}
                          optimizedAt={optimizedAt}
                          isAwardDraft={isAwardDraft}
                          lineItemShares={lineItemShares}
                        />
                      </div>
                      <div
                        style={{
                          position: 'relative',
                          height: 0,
                          overflow: 'hidden'
                        }}
                      >
                        <div>
                          <InnerContent
                            {...state}
                            awardColumnStyle={awardColumnStyle}
                            onScroll={onScroll}
                            rowCount={1}
                            height={totalTableHeight}
                            rowHeight={getRowHeight}
                            width={width + sizeOfScroll}
                            data={responses}
                            lineItems={lineItems}
                            showLineItemsWithNoResponse={showLineItemsWithNoResponse}
                            columnCount={columnCount}
                            columnWidth={getColumnWidthHelper}
                            checkBestBid={getGreenHighlightClassForLineItem}
                            hiddenColumns={hiddenColumns}
                            currencySymbol={currencySymbol}
                            hideSuppliers={hideSuppliers}
                            _headerRenderer={_dummyHeaderRenderer}
                            getColumnInstance={getColumnInstance}
                            getColumnValue={getColumnValue}
                            lineErrorSet={lineErrorSet}
                            isDummy
                            measure={measureHeader}
                            optimizedAt={optimizedAt}
                            setContentRef={setDummyContentRef}
                            isTransformationAppliedAtLineItemLevel={isTransformationAppliedAtLineItemLevel}
                            isAwardDraft={isAwardDraft}
                            lineItemShares={lineItemShares}
                          />
                        </div>
                      </div>
                    </Fragment>
                )}
              }
            </AutoSizer>
          )
        }
      }
      </ScrollSync>
    </div>
  );
}

export default App;
