export const parseHTML = (event) => {
    const pastedData = (typeof event === 'string') ? event : event.clipboardData.getData('text/html');
    const rows = [];
    const dummyContainer = document.createElement('div');
    dummyContainer.innerHTML = pastedData;
    const dummyTable = dummyContainer.querySelectorAll('table tr');
    if (dummyTable.length === 0) {
      // 
      // check if only single cell
      // is pasted
      const span = dummyContainer.querySelector('span');
      return [
        [
          span ? span.innerHTML : typeof event === 'object' && event.clipboardData.getData('text/plain')
        ]
      ];
    }
    //
    // multiple cells are pasted
    // in this case
    dummyTable.forEach(tableRow => {
      const cells = tableRow.querySelectorAll('td');
      const row = [];
      cells.forEach(cell => {
        row.push(cell.innerHTML);
      });
      rows.push(row);
    });
    return rows;
}
