const event_savings_column = {
    "actual_formula": "", 
    "alternate_bid_from_supplier" : "no",
    "data_need_for_all_line_items": "no", 
    "decimal_spaces": 0, 
    "formula": "",
    "input_field_type": "Price", 
    "is_column_hidden": false, 
    "price_transformation_factor": "no",
    "required_for_formula": "no", 
    "requires_a_total": "yes", 
    "supplier_can_see": "yes", 
    "term_name": "Event Savings", 
    "ucid": "event_saving", 
    "who_fills": "Buyer"
  }

function setColumnsForLot(response) {
    response.column_data_for_buyer = response.items.column_data.filter(
      (col) => col.who_fills === 'Buyer' && (col.price_transformation_factor === 'no' || !col.price_transformation_factor)
    )
    response.column_data_for_buyer.push(event_savings_column)
    
    response.column_data_for_supplier = response.items.column_data.filter(
      (col) => !(col.who_fills === 'Buyer' && (col.price_transformation_factor === 'no' || !col.price_transformation_factor))
    )
    response.setColumns = response.column_data_for_buyer.concat(response.column_data_for_supplier)
  
    response.alternateBidColumns = response.column_data_for_buyer.filter(
      (col) => col.alternate_bid_from_supplier === 'yes' && !col.is_column_hidden
    )
    
    if (response.bid_action && response.bid_action === 'e') {
      const qtyColumnIndex = response.column_data_for_buyer.findIndex(
        (col) => col.ucid === 'quantity'
      )
      response.column_data_for_supplier.unshift(response.column_data_for_buyer[qtyColumnIndex])
      response.column_data_for_buyer[qtyColumnIndex] = {
        ...response.column_data_for_buyer[qtyColumnIndex], 
        term_name: 'Total Supplier Quantity'
      }
    }
  
    const supplier_data_not_needed_columns = response.items.column_data.filter(
      (col) => col.data_need_for_all_line_items !== 'yes' && col.who_fills === 'Supplier'
    )
    
    if (supplier_data_not_needed_columns.length > 0) {
      response.data_not_needed = true
    } else {
      response.data_not_needed = false
    }
    response.normalSupplierColumns = response.column_data_for_supplier;
    response.alternateSupplierColumns = [
      ...response.alternateBidColumns, 
      ...response.column_data_for_supplier
    ]
    return response
}

export default setColumnsForLot;