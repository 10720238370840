function setSuppliersForLot(response) {
    let sortedSupplierIds: string[] = []
    let colsToDisplayOnGlobalHideShow: any = [];
    let alternateBidSuppliers = {}
    response.allSuppliers = {}
    const { supplier_responses } = response;
    const hasResponded = supplierId => supplierId && supplierId in supplier_responses;
    const hasNotResponded = supplierId => !hasResponded(supplierId);
    for (let supplierId in response.suppliers) {
      sortedSupplierIds.push(supplierId.toString())
      response.suppliers[supplierId].columns = response.column_data_for_supplier
      response.suppliers[supplierId].column_length = response.column_data_for_supplier.length
      if (response.supplier_responses[supplierId + '_alternate']) {
        sortedSupplierIds.push(supplierId + '_alternate')
        let altSupplier = {...response.suppliers[supplierId]}
        altSupplier.supplier_id = altSupplier.supplier_id + '_alternate'
        altSupplier.columns = response.alternateSupplierColumns
        altSupplier.column_length = response.alternateSupplierColumns.length
        alternateBidSuppliers[altSupplier.supplier_id] = altSupplier
      }
      colsToDisplayOnGlobalHideShow.push(supplierId);
      response.allSuppliers[supplierId] = response.suppliers[supplierId];
    }
    const sortFunc = (supplier_id_1, supplier_id_2) => {
      const { suppliers } = response;
      const getName = supplierId => (
        suppliers[supplierId]
        && suppliers[supplierId].supplier_name
        && suppliers[supplierId].supplier_name.toLowerCase()
      );
      return getName(supplier_id_1) < getName(supplier_id_2) ? -1 : 1;
    }
    const respondedSuppliers = sortedSupplierIds.filter(hasResponded);
    const emptySuppliers = sortedSupplierIds.filter(hasNotResponded);
    respondedSuppliers.sort(sortFunc);
    emptySuppliers.sort(sortFunc);
    response.suppliersOrder = [
      ...respondedSuppliers,
      ...emptySuppliers
    ];
    response.suppliers = {
      ...response.suppliers,
      ...alternateBidSuppliers
    };
    return response
}

export default setSuppliersForLot;