function getNumberFromString(string){
    try{
      let number = parseFloat(string.toString().replace(/,/g,''))
      return number ? number : 0;
    }
    catch(e){
      return 0
    }
}

export default getNumberFromString;