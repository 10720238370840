import React, { Fragment, useEffect } from 'react';
import { MultiGrid } from 'react-virtualized';
import classNames from 'classnames';

import layoutStyles from '../layout.module.css';
import tableStyles from './table.module.css';
import { parseHTML } from '../utils/excelService';

import Column from './utils/Column';
import { AWARD_OPTIMIZED_AT } from './utils/award';

const STYLE = {
    border: '1px solid #ddd',
};
  
const STYLE_BOTTOM_LEFT_GRID = {
    borderRight: '2px solid #aaa',
    backgroundColor: '#f7f7f7',
};

const STYLE_TOP_LEFT_GRID = {
    borderBottom: '2px solid #aaa',
    borderRight: '2px solid #aaa',
    fontWeight: 'bold',
};

const STYLE_TOP_RIGHT_GRID = {
    borderBottom: '2px solid #aaa',
    fontWeight: 'bold',
};


function InnerContent({
    _headerRenderer,
    awardBy,
    awardColumnStyle,
    checkBestBid,
    columnCount,
    columnWidth,
    currencySymbol,
    data,
    excludedSet,
    getColumnValue,
    getColumnInstance,
    height,
    ignoredSet,
    isAwardDraft,
    isDummy,
    isTransformationAppliedAtLineItemLevel,
    lineItems,
    lineErrorSet,
    measure,
    optimizedAt,
    rowHeight,
    setContentRef,
    setShares,
    showLineItemsWithNoResponse,
    ...props
  }) {
    function _cellRenderer({
      columnIndex,
      key,
      rowIndex: rowIndexOriginal,
      style
    }) {
      const width = columnWidth({ index: columnIndex });
      if (!width) {
        return null;
      }
      const rowIndex = rowIndexOriginal - 1;
      const columnValue = getColumnValue(rowIndex, columnIndex);
      const isForSupplier = Array.isArray(columnValue);
      const parentColumn = getColumnInstance(rowIndex, columnIndex);
      const row = lineItems[rowIndex];
      if (rowIndexOriginal === 0) {
        return (
          _headerRenderer({
            columnIndex,
            key,
            rowIndex,
            style
          })
        )
      }
      if (showLineItemsWithNoResponse && !row.is_empty_row) {
        return null;
      }
      const EMPTY_ROW_BACKGROUND = 'rgba(255, 82, 0, 0.10)'
      const supplierId = parentColumn.safeAccess('supplier_id');
      const { urid: rowId } = row;

      const isExcluded = excludedSet.has(rowId);
      const isIgnored = isExcluded && ignoredSet.has(rowId);
      
      const getLineAward = () => {
        if (isIgnored) {
          return '';
        }
        if (optimizedAt === 'lot') {
          return '-';
        }
        const { lineItemShares } = props;
        return (
            lineItemShares
            && lineItemShares[rowId]
            && lineItemShares[rowId][supplierId]
        ) || '';
      }

      const onLineAwardInput = (e) => {
        console.log('on line award input: ', e.target.value)
        if (isNaN(e.target.value)) {
          return;
        }
        const parsedValue = parseInt(e.target.value, 10);
        const value = (
          awardBy === 'Qty'
          ? parsedValue
          : Math.min(parsedValue, 100)
        );
        setShares(shares => {
          const oldLineShares = shares.line_item_level_shares ? shares.line_item_level_shares[rowId] : {};
          let newLineShares = {
            ...oldLineShares,
          };
          if (isNaN(value)) {
            delete newLineShares[supplierId];
          }
          else {
            newLineShares[supplierId] = value;
          }
          return ({
            ...shares,
            line_item_level_shares: {
              ...shares.line_item_level_shares,
              [rowId]: newLineShares,
            }
          });
        });
      }

      const onLinePaste = (e) => {
        const pastedData = parseHTML(e);
        if (!(Array.isArray(pastedData) && (pastedData.length > 1 || pastedData[0].length > 1))) {
          return;
        }
        e.preventDefault();
        let index = rowIndex;
        setShares(shares => {
          const oldLineShares = shares.line_item_level_shares || {};
          pastedData.forEach(data => {
            if (index >= lineItems.length) {
              return;
            }
            const parsedValue = parseInt(data[0], 10);
            const value = (
              awardBy === 'Qty'
              ? parsedValue
              : Math.min(parsedValue, 100)
            );
            const currentRowId = lineItems[index].urid;
            const currentShares = oldLineShares[currentRowId] || {};
            currentShares[supplierId] = value;
            oldLineShares[currentRowId] = currentShares;
            index++;
          });
          return ({
            ...shares,
            line_item_level_shares: {
              ...oldLineShares
            }
          });
        });
      }
      
      const showError = isAwardDraft && optimizedAt === 'line' && lineErrorSet && lineErrorSet.has(rowId);
      return (
        <div
          key={key}
          className={classNames({
            'error-cell': showError,
            'excluded': isExcluded
          })}
          style={{
            ...style,
            background: row.is_empty_row ? EMPTY_ROW_BACKGROUND : ''
          }}
        >
          <div
            className={classNames(
              {
                [layoutStyles.flex]: isForSupplier,
                [tableStyles.supplierGroup]: isForSupplier,
              },
              {
                [tableStyles.padCell]: !isForSupplier,
                [tableStyles.awardCheckbox]: parentColumn.isAwardColumn
              },
              tableStyles.row,
              tableStyles.borderRight,
              tableStyles.supplierValue
            )}
          >
          {
            Array.isArray(columnValue)
            ? columnValue.map(({
                value: originalValue,
                ...rest
            }, index) => {
              const value = !isIgnored ? originalValue : null;
                const {
                    ucid,
                    isAwardDraft
                } = rest;
                const column = new Column(rest);
                const transformationCondition = !column.isTransformation || isTransformationAppliedAtLineItemLevel;
                return (
                    <div
                        key={index}
                        className={classNames(
                          layoutStyles.flex,
                          layoutStyles.flex1,
                          !isIgnored && checkBestBid(rowIndex, columnIndex, ucid),
                          {
                              [tableStyles.padCell]: true,
                              [tableStyles.editable]: column.isEditable && !column.isLotOptimized && !isExcluded
                          },
                          tableStyles.classicColumn
                        )}
                    >
                    {
                        !column.isEditable ?
                        (
                            <span
                              style={{
                                display: column.isEditable ? 'none' : 'flex',
                                justifyContent: column.justifyContent,
                                width: '100%'
                              }}
                            >
                                <span>
                                {
                                    column.isPrice
                                    && transformationCondition
                                    && !showLineItemsWithNoResponse
                                    && value
                                    && currencySymbol
                                }
                                </span>
                                {
                                    column.type === 'Text' ? (
                                        <span
                                            className={tableStyles.cellContent}
                                            dangerouslySetInnerHTML={{
                                                __html: value
                                            }}
                                            title={window.htmlToFormattedText(value)}
                                        ></span>
                                    ) : (
                                        <span
                                            className={tableStyles.cellContent}
                                        >
                                        {
                                            transformationCondition
                                            && !showLineItemsWithNoResponse
                                            && value
                                        }
                                        </span>
                                    )
                                }
                                {
                                column.isPercentage
                                && transformationCondition
                                && (
                                    <span className={tableStyles.suffix}>
                                    %
                                    </span>
                                )
                                }
                            </span>
                        ) : (
                          <Fragment>
                            <input
                              className={tableStyles.awardInput}
                              value={getLineAward()}
                              data-supplier={supplierId}
                              onChange={onLineAwardInput}
                              onPaste={onLinePaste}
                              disabled={isExcluded}
                              style={awardColumnStyle}
                            />
                            {
                              awardBy === '%' && optimizedAt === 'line' && (
                                <span>%</span>
                              )
                            }
                          </Fragment>
                        )
                    }
                    </div>
                )
            }) : columnValue
          }
          </div>
        </div>
      );
    }
  
    useEffect(() => {
      if (isDummy) {
        measure();
      }
    }, [isDummy])
  
    const refProps = {}
    if (setContentRef) {
      refProps.ref = setContentRef
    }
  
    return (
      <MultiGrid
        cellRenderer={_cellRenderer}
        enableFixedColumnScroll
        enableFixedRowScroll
        height={height}
        rowHeight={rowHeight}
        style={STYLE}
        styleBottomLeftGrid={STYLE_BOTTOM_LEFT_GRID}
        styleTopLeftGrid={STYLE_TOP_LEFT_GRID}
        styleTopRightGrid={STYLE_TOP_RIGHT_GRID}
        hideTopRightGridScrollbar
        hideBottomLeftGridScrollbar
        columnCount={columnCount}
        columnWidth={columnWidth}
        fixedRowCount={1}
        {...props}
        {...refProps}
      />
    )
  }

export default InnerContent;
