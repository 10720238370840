function setTransformation(response) {
    if (response.compute_transformed_cost !== 'yes') {
      return false
    }
    const transformedCol = response.column_data_for_supplier.find((column)=>column.ucid === 'tranformed_price')
    if (transformedCol.apply_to === 'All Line items') {
      return true
    }
    return false
}

export default setTransformation;
