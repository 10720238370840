import React from 'react';
import { Checkbox } from '@material-ui/core';

const EmptyCheck = () => (
    <div style={{
        height: 15,
        width: 15,
        border: '1px solid rgb(169 169 169)',
        borderRadius: '3px',
        marginTop: '2px'
    }}></div>
)

const CustomCheckBox = props => (
    <Checkbox
        icon={<EmptyCheck />}
        {...props}
    />
)

export default CustomCheckBox;
