import {
    useMemo,
    useState,
    useEffect
} from 'react';
import axios from 'axios';

import setColumnsForLot from './helpers/setColumnsForLot';
import setSuppliersForLot from './helpers/setSuppliersForLot';
import getFormattedSupplierResponses from './helpers/getFormattedSupplierResponses';
import getFormattedBuyerValues from './helpers/getFormattedBuyerValues';
import setTransformation from './helpers/setTransformation';

const SR_NO_COLUMN = {
    "ucid": "sr_no",
    "term_name": "Sr. No.",
    "who_fills": "Buyer"
}

const useLot = ({
    eventId,
    lotId,
    baseUrl,
    subDomain,
    localeRef,
    scenarioId,
    accessToken
  }) => {
    const initData = useMemo(() => ({
      buyerColumns: [],
      bidAction: null,
      supplierResponses: {},
      suppliersOrder: [],
      suppliers: {},
      normalSupplierColumns: [],
      alternateSupplierColumns: [],
      lineItems: [],
      responses: {},
      isTransformationAppliedAtLineItemLevel: false,
      totals_row: {},
      buyer_totals: {},
      surrogate_bidders_data: {},
      transformation_costs_data: {}
    }), []);
  
    const [data, setData] = useState({
      ...initData
    });
  
    useEffect(() => {
      if (!(eventId && lotId && baseUrl && subDomain && accessToken)) {
        return;
      }
      setData({ ...initData })
      const headers = {
        'X-Sub-Domain': subDomain,
        'Authorization': "Bearer " + accessToken
      };
      var url = baseUrl + `/sourcing-request-micro/sourcing-events/${eventId}/pricing-sheet/lot-pricing-sheet/${lotId}/`
      const urlParams = new URLSearchParams(window.location.search);
      const sceneId = urlParams.get('sceneId');
      if(sceneId && sceneId != 0) {
        url += `?scenario_id=${sceneId}`
      }
      axios.get(url, {
        headers
      })
          .then(({ data: response }) => {
            response.data.surrogate_bidders_data = Object.assign({},
              ...response.data.surrogate_bidders_data.map(key => ({[key['supplier_id']]: key}))
              )
            
            response.data.column_info = Object.assign({}, ...response.data.items.column_data.map(key => ({[key['ucid']]: key})))
            
            response.data.supplier_column_info = Object.assign(
              {}, 
              ...response.data.items.column_data.filter(
                (col)=> col.who_fills === 'Supplier' && col.data_need_for_all_line_items !== 'yes'
            ).map(key => ({[key['ucid']]: key})))
  
            const formatValue = (value, decimalSpaces) => {
              const formatter = new Intl.NumberFormat(localeRef.current, {
                maximumFractionDigits: decimalSpaces,
                minimumFractionDigits: decimalSpaces
              });
              return formatter.format(value);
            };
  
            response.data = setColumnsForLot(response.data)
            response.data = getFormattedSupplierResponses(response.data, formatValue)
            response.data = getFormattedBuyerValues(response.data, formatValue)
            response.data = setSuppliersForLot(response.data)
            response.data.isTransformationAppliedAtLineItemLevel = setTransformation(response.data)
            const event = new CustomEvent('buyerTotals', {
              detail: response.data
            });
            window.dispatchEvent(event);
  
            const {
              column_data_for_buyer,
              column_info,
              compute_transformed_cost,
              compute_total_cost,
              suppliersOrder,
              suppliers,
              normalSupplierColumns,
              alternateSupplierColumns,
              items: {
                row_data: lineItems
              },
              supplier_responses: responses,
              totals_row,
              buyer_totals,
              surrogate_bidders_data,
              transformation_costs_data,
              bid_action: bidAction
            } = response.data;
  
            setData(data => ({
              ...data,
              bid_action: bidAction,
              bidAction,
              buyerColumns: [SR_NO_COLUMN, ...column_data_for_buyer],
              column_info,
              compute_total_cost,
              compute_transformed_cost,
              suppliersOrder,
              suppliers,
              normalSupplierColumns,
              alternateSupplierColumns,
              lineItems,
              responses,
              isTransformationAppliedAtLineItemLevel: response.data.isTransformationAppliedAtLineItemLevel,
              totals_row,
              buyer_totals,
              surrogate_bidders_data,
              transformation_costs_data
            }));
  
          });
    }, [
      eventId,
      lotId,
      baseUrl,
      subDomain,
      localeRef,
      scenarioId,
      accessToken,
      initData
    ]);
  
    return [data, setData];
}

export default useLot;
