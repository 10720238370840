import getNumberFromString from "./getNumber"

function getFormattedBuyerValues(response, formatValue) {
    let buyer_value:number
    response.buyer_totals.baseline_price = getNumberFromString(response.buyer_totals.baseline_price) - getNumberFromString(response.buyer_totals.baseline_price_not_responded)
    response.buyer_totals.total_event_saving_at_line = 0
    if (Object.values(response.buyer_totals.event_saving_at_line).length > 0) {
      for (let row in response.buyer_totals.event_saving_at_line) {
        if (response.buyer_totals.event_saving_at_line[row]['event_saving']) {
          response.buyer_totals.total_event_saving_at_line += response.buyer_totals.event_saving_at_line[row]['event_saving']
          response.buyer_totals.event_saving_at_line[row]['event_saving'] = (
            formatValue(
              getNumberFromString(response.buyer_totals.event_saving_at_line[row]['event_saving']),
              2
            )
          )
        }
      }
  
      response.buyer_totals.total_event_saving_at_line = formatValue(
        getNumberFromString(response.buyer_totals.total_event_saving_at_line), 
        2
      )
  
      response.buyer_totals.total_event_saving_at_line_percent = formatValue(
        getNumberFromString(response.buyer_totals.total_event_saving_at_line), 
        2
      )
    } else {
      response.buyer_totals.total_event_saving_at_line_percent = ""
    }
  
    response.buyer_totals.baseline_price = formatValue(
      getNumberFromString(response.buyer_totals.baseline_price), 
      2
    )
  
    response.buyer_totals.baseline_price_not_responded = formatValue(
      getNumberFromString(response.buyer_totals.baseline_price_not_responded), 
      2
    )
    
    if (Object.values(response.buyer_totals.event_saving_at_lot).length>0) {
      response.buyer_totals.event_saving_at_lot = formatValue(
        getNumberFromString(response.buyer_totals.event_saving_at_lot), 
        2
      )
    } else {
      response.buyer_totals.event_saving_at_lot_percent = ''
    }
    if (response.bid_action === 'e') {
      for (let row of response.items.row_data) {
        for (let column in row) {
          if (response.column_info[column] 
            && response.column_info[column].input_field_type 
            && response.column_info[column].input_field_type !== 'Text') {
              buyer_value = getNumberFromString(row[column])
              row[column] = formatValue(
                buyer_value, 
                response.column_info[column].decimal_spaces
              )
          }
        }
      }
    }
    return response
}

export default getFormattedBuyerValues;