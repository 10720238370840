function getGreenHighlightColumn(lot) {
    if (lot.compute_transformed_cost === 'yes') {
      return 'tranformed_price'
    }
    else if (
      lot.compute_total_cost === 'yes' 
      || lot.bid_action === 'b'
      || lot.bid_action === 'd') {
      return 'total_cost'
    }
    else {
      return 'extended_price'
    }
}

export default getGreenHighlightColumn;