import getSafe from ".";
import getGreenHighlightColumn from "./getGreenHighlightColumn";
import getNumberFromString from "./getNumber";


/** 
 * arguments schema
 * * * * * * * * * * * * * * * * * * *
 * 
 * bestBid: number
 * columns: Object
 *   - key: ucid - column id
 *   - value: column data object
 *   - Ex:
 *       - "ucid": "1634898076696"
 *       - "formula": ""
 *       - "apply_to": "All Line items"
 *       - "max_value": ""
 *       - "min_value": ""
 *       - "term_name": "Brand"
 *       - "who_fills": "Buyer"
 *       - "actual_formula": ""
 *       - "decimal_spaces": 0
 *       - "input_field_type": "Text"
 *       - "requires_a_total": "no"
 *       - "supplier_can_see": "yes"
 *       - "required_for_formula": "no"
 *       - "data_need_for_all_line_items": "yes"
 *
 * formatValue             : Function
 * 
 * greenHighlightColumn    : string - column id of best bid
 * 
 * supplierResponse        : Object
 *   - row_data: { urid: response },
 *   - totals_row: { ucid: total_value }
 * 
 * Example of supplier_response:
 * 
 *   - "row_data":
 *      - "1634897787":                                       # row id of line item
 *         - "uom": "each"                                    # unit of measurement - in built
 *         - "urid": "1634897787"                             # row id              - in built
 *         - "quantity": "10"                                 # quantity            - in built
 *         - "item_name": "SPARTAN 150 M weights 80 lb."      # item name           - in built
 *         - "unit_price": "265,626.90"                       # unit price          - in built
 *         - "1634898076696": "ABC"                           # custom field
 *         - "baseline_price": "3,680,000.00"                 # baseline price      - in built
 *         - "extended_price": "2,656,269.00"                 # extended price      - in built
 *   - "totals_row":
 *       - "quantity": "10"
 *       - "unit_price": "265,626.90"
 *       - "extended_price": "2,656,269.00"
 *       - "baseline_price": "3,680,000.00"
 *
 */
const assignBestBidAtLot = (
  bestBid,
  columns,
  formatValue,
  greenHighlightColumn,
  supplierResponse
) => {
  const updatedSupplierResponse = {...supplierResponse};
  // ========================
  // remove old best bid data
  // ========================
  updatedSupplierResponse.totals_row.greenHighlight = null;
  for (let column in supplierResponse.totals_row) {
    const columnInfo = columns[column];
    if (
      !columnInfo
      || supplierResponse.totals_row[columnInfo.ucid] === ""
    ) {
      continue;
    }
    const { ucid } = columnInfo;
    const sup_value = getNumberFromString(supplierResponse.totals_row[ucid]);
    if (sup_value <= 0) {
      continue;
    }
    updatedSupplierResponse.totals_row[ucid] = (
      formatValue(sup_value, columnInfo.decimal_spaces)
    )
    if (greenHighlightColumn !== ucid) {
      continue;
    }
    if (bestBid === sup_value) {
      updatedSupplierResponse.totals_row.greenHighlight = greenHighlightColumn;
    }
  }
  return updatedSupplierResponse
}

function getFormattedSupplierResponses(response, formatValue) {
    let responded_row_data = {}
    const greenHighlightColumn = getGreenHighlightColumn(response)
    let sup_value:number
    for (let sup in response.supplier_responses) {
      if (response.supplier_responses[sup]['row_data']
        && Object.values(response.supplier_responses[sup]['row_data']).length > 0) {
        for (let row of response.supplier_responses[sup]['row_data']) {
          for (let ucid in row) {
            const columnInfo = response.column_info[ucid]
            if (columnInfo 
              && columnInfo.input_field_type 
              && columnInfo.input_field_type !== 'Text'
              && row[ucid] !== ""
            ) {
              sup_value = getNumberFromString(row[ucid])
              row[ucid] = formatValue(sup_value, columnInfo.decimal_spaces);
              if (
                greenHighlightColumn === ucid
                && getSafe(() => response.buyer_totals.event_saving_at_line[row.urid].total) === sup_value
              ) {
                  row.greenHighlight = greenHighlightColumn;
                }
            }
            if (
              response.supplier_column_info 
              && response.supplier_column_info[ucid]
              && row[ucid] !== '') {
              responded_row_data[row.urid] = true
            }
          }
        }
      }
      if (
        sup
        && response
        && response.supplier_responses
        && response.supplier_responses[sup]
      ) {
        const bestBid = response.buyer_totals.event_saving_at_lot.total;
        const greenHighlightColumn = getGreenHighlightColumn(response);
        const singleResponseByASupplier = response.supplier_responses[sup];
        const updatedResponseWithBestBid = assignBestBidAtLot(
          bestBid,
          response.column_info,
          formatValue,
          greenHighlightColumn,
          singleResponseByASupplier,
        );
      }
      if (response.supplier_responses[sup].row_data) {
        response.supplier_responses[sup].row_data = Object.assign({},
          ...response.supplier_responses[sup].row_data.map(key => ({[key['urid']]: key}))
        )
      }
    }
  
    if (Object.values(responded_row_data).length > 0) {
      for (let row of response.items.row_data) {
        if (responded_row_data[row.urid]) {
          row.is_empty_row = false
        } else {
          row.is_empty_row = true
        }
      }
    } else if (Object.values(responded_row_data).length === 0 && response.data_not_needed){
      for (let row of response.items.row_data) {
        row.is_empty_row = true
      }
    }
    return response
}

export { assignBestBidAtLot }

export default getFormattedSupplierResponses;