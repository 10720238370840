export const AWARD_OPTIMIZED_AT = {
    LINE: 'line_item_level',
    LOT: 'lot'
}

export const AWARD_COLUMN_IDS = {
    'excluded': 'excluded',
    'ignored': 'ignored',
    'award_savings': 'award_savings'
};

export const awardColumns = [
    {
      ucid: AWARD_COLUMN_IDS.excluded,
      term_name: 'Exclude from award',
      who_fills: 'Buyer'
    },
    {
      ucid: AWARD_COLUMN_IDS.ignored,
      term_name: 'Ignore Spend',
      who_fills: 'Buyer'
    },
    {
      ucid: 'award_savings',
      term_name: 'Award Savings',
      who_fills: 'Buyer',
      decimal_spaces: 2,
      input_field_type: 'Price',
      requires_a_total: 'yes'
    }
];

export const awardValueColumn = ({
  awardBy,
  isAwardDraft,
  optimizedAt
}) => ({
  awardBy,
  isAwardDraft,
  isEditable: true,
  optimizedAt,
  requires_a_total: 'yes',
  term_name: 'Award ' + awardBy,
  ucid: 'share',
  input_field_type: awardBy === 'Qty' ? 'number' : 'percentage'
});
