//@ flow
import axios from "axios";

type ServerOptions = {
    subDomain: string;
    baseUrl: string;
    accessToken: string;
};

const updateLotAward = async (payload, options: ServerOptions, scenarioId) => {
    const {
        eventId,
        lot_id,
        ...rest
    } = payload;
    var url = (
        options.baseUrl
        + '/sourcing-request-micro/sourcing-events/'
        + payload.eventId
        + '/pricing-sheet/award/'
        + lot_id
        + '/'
    );
    if (scenarioId && scenarioId != 0) {
        url += `?pending_award=${scenarioId}`
    }
    const result = await axios.put(url, rest, {
        headers: {
            'X-Sub-Domain': options.subDomain,
            'Authorization': "Bearer " + options.accessToken
        }
    });
    window.dispatchEvent(
        new CustomEvent('lotUpdated', {
            detail: {
                payload: 'Lot updated successfully',
                stopLoading: true,
                type: 'success',
                lot: result.data.data,
                errors: result.data.errors
            }
        })
    );
    return result.data;
}

export default updateLotAward;
