import React from 'react';
import ReactDOM from 'react-dom';
import ResponseAnalysisOfLot from './ResponseAnalysisOfLot';
import Assignment from './Assignment';
import reactToWebComponent from 'react-to-webcomponent';

import './index.css';

customElements.define('ra-table', 
    reactToWebComponent(
        ResponseAnalysisOfLot,
        React,
        ReactDOM
    )
);

customElements.define('assign-prices', 
    reactToWebComponent(
        Assignment,
        React,
        ReactDOM
    )
);

if (window.location.href.includes('assign-prices')) {
    const assignElement = document.createElement('assign-prices');
    document.body.appendChild(assignElement);
}
