import { AWARD_COLUMN_IDS } from "./award";

export const columnIds = {
  transformed_price: 'tranformed_price'
};

class Column {
    data = {}
    isValid = false
  
    constructor(data) {
      this.data = data;
      this.isValid = (
        data !== null
        && data !== undefined
        && typeof data === 'object'
        && Object.keys(data).length > 0
      );
    }
  
    static types = {
      PRICE: 'Price'
    }
  
    get isTransformation() {
      return this.safeAccess('price_transformation_factor') === 'yes' || this.ucid === 'tranformed_price'
    }
  
    get isPTF() {
      return this.safeAccess('price_transformation_factor') === 'yes'
    }
  
    get isAlternateBid() {
      const supplier_id = this.safeAccess('supplier_id');
      return typeof supplier_id === 'string' && supplier_id.endsWith('_alternate');
    }
  
    get isPrice() {
      return this.type === 'Price'
    }
  
    get isPercentage() {
      return this.type === 'Percentage' || this.safeAccess('award_by') === 'percentage'
    }
  
    get isNumber() {
      return this.type === 'Number'
    }
  
    get justifyContent() {
      switch(this.type) {
        case 'Price': {
          return 'space-between';
        }
        case 'Number':
        case 'Percentage': {
          return 'flex-end';
        }
        default: {
          return 'flex-start';
        }
      }
    }
  
    get isBuyerFilled() {
      return this.safeAccess('who_fills') === 'Buyer';
    }
  
    get isSupplierFilled() {
      return this.safeAccess('who_fills') === 'Supplier';
    }
  
    safeAccess(property) {
      return this.data && this.data[property];
    }
  
    get termName() {
      return this.safeAccess('term_name');
    }
  
    get type() {
      return this.safeAccess('input_field_type');
    }
  
    get ucid() {
      return this.safeAccess('ucid');
    }
  
    get showTotal() {
      return this.safeAccess('requires_a_total') === 'yes';
    }
  
    get columns(): any[] {
      return this.safeAccess('columns') || [];
    }

    get isEditable(): boolean {
      return this.safeAccess('isEditable');
    }

    get optimizedAt(): string {
      return this.safeAccess('optimizedAt');
    }

    get isLotOptimized() {
      return this.optimizedAt === 'lot';
    }

    get isLineOptimized() {
      return this.optimizedAt === 'line';
    }

    get isAwardColumn() {
      return [AWARD_COLUMN_IDS.excluded, AWARD_COLUMN_IDS.ignored].includes(this.ucid);
    }
}

export default Column;
