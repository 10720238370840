import React, {
    useEffect,
    useRef,
    useState
} from 'react';
import {
    AutoSizer,
    MultiGrid
} from 'react-virtualized';
import {
    makeStyles,
    Checkbox,
    createTheme,
    ThemeProvider
} from '@material-ui/core';
import classNames from 'classnames';
import { scrollbarSize } from 'dom-helpers';

import layoutStyles from '../layout.module.css';
import styles from './index.module.css';

import '../assets/fonts/selawik.css';
import './index.css';
import './scrollbars.scss';
import './normalize.scss';

const EmptyCheck = () => (
    <div style={{
        height: 16,
        width: 16,
        border: '1px solid rgb(169 169 169)',
        borderRadius: '3px',
        marginTop: '2px'
    }}></div>
)

const useCheckboxStyles = makeStyles((theme) => ({
    backgroundColorOnWholeIcon: {
      '&.Mui-disabled': {
        opacity:0.5,
        },
    },
  }));

const theme = createTheme({
    palette: {
        secondary: {
            main: '#2f78c4'
        }
    }
})

const SupplierAssignment = () => {

    const [data, setData] = useState({
        currency: '',
        lots: [],
        suppliers: [],
        takePrice: false,
        isDisabled: false,
        isLoading: false,
        eventType: null,
        auctionType: null,
        localeCode: null,
        sourcingType: null,
        isLive: false,
    });

    const grid = useRef();

    const setGridRef = el => {
        grid.current = el;
    }

    const {
        lots,
        suppliers,
        takePrice,
        isDisabled,
        isLoading,
        eventType,
        auctionType,
        sourcingType,
        isLive,
    } = data;

    const makeMessage = (type, data) => ({
        type,
        payload: data,
        fromAssign: true
    })
    
    useEffect(() => {
        const isValid = obj => (
            obj
            && typeof obj === 'object'
            && Object.keys(obj).length > 0
        );

        postMessageToParent({
            initAssign: true
        });

        const onAssignment = e => {
            const newData = e.data;
            if (isValid(newData)) {
                setData(oldData => ({
                    ...oldData,
                    ...newData
                }));
            }
        }

        window.addEventListener('message', onAssignment);

        return () => {
            window.removeEventListener('message', onAssignment);
        };
    }, []);

    const postMessageToParent = data => {
        window.parent.postMessage(data, '*');
    }

    const classes = useCheckboxStyles();
    
    const assignAll = (event, lotIndex) => {
        if (isLoading) {
            return
        }
        const assignAllEvent = makeMessage('assignAll', {
            checked: event.target.checked,
            lotIndex
        });
        postMessageToParent(assignAllEvent)
    }

    const assignOne = (event, lotIndex, supplier) => {
        if (isLoading) {
            return
        }
        const assignEvent = makeMessage('assignOne', {
            checked: event.target.checked,
            lotIndex,
            supplier
        })
        postMessageToParent(assignEvent)
    }

    const areAllAssigned = lotSuppliers => {
        return lotSuppliers 
            && Array.isArray(suppliers)
            && Object.keys(lotSuppliers).length === suppliers.length 
    }

    const onPriceLimitPaste = (event, supplierIndex, lotIndex) => {
        event.preventDefault();
        if (isLoading) {
            return
        }
        const pastedData = event.clipboardData.getData('text/html');
        const pasteEvent = makeMessage('priceLimitPaste', {
            payload: pastedData,
            supplierIndex,
            lotIndex
        });
        postMessageToParent(pasteEvent);
    }

    const cleanThePrice = (ceilingPrice) => {
        if(ceilingPrice.charAt(0) === '.')
          ceilingPrice = '0' + ceilingPrice.substring(0)
        try{
          let value = ceilingPrice.replace( /^\D+/g, '');
          value = value.replace(/,/g,'');
          value = parseFloat(value)
          
          if(value) {
            const formatter = new Intl.NumberFormat(data.localeCode, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
            });
            return formatter.format(value);
          }
          else{
            value = ''
          }
          return value;
        }
        catch(e){
        }
      }

    const updatePrice = (event, supplier, lotIndex) => {
        if (isLoading) {
            return
        }
        event.target.innerText = cleanThePrice(event.target.innerText);
        const priceEvent = makeMessage('price', {
            value: event.target.innerText,
            supplier,
            lotIndex
        });
        postMessageToParent(priceEvent);
    }

    const getName = lot => {
        return (
            lot
            && lot.name
            ? lot.name.length > 20 ? lot.name.slice(0, 20) : lot.name
            : ''
        );
    }

    const _cellRenderer = ({    
        columnIndex,
        rowIndex,
        key,
        style
    }) => {
        if (columnIndex === 0) {
            const supplier = rowIndex > 0 ? suppliers[rowIndex - 1] : null;
            return (
                <div
                    style={style}
                    className={classNames(
                        {
                            [styles.th]: rowIndex === 0,
                            [styles.supplierName]: rowIndex > 0,
                            [styles.fontSelawik]: rowIndex > 0
                        },
                        layoutStyles.borderLeft,
                        layoutStyles.borderBottom,
                        layoutStyles.borderRight
                    )}
                    key={key}
                    title={supplier && supplier.company_name}
                >
                    {
                        rowIndex === 0
                        ? 'Supplier List'
                        : supplier && supplier.company_name
                    }
                </div>
            )
        }
        const lot = lots[columnIndex - 1];
        const lotName = getName(lot);
        if (rowIndex === 0) {
            return (
                <div
                    style={style}
                    key={key}
                >
                    <div
                        className={classNames(styles.th)}
                        title={lot.name}
                    >
                        {
                            lot && (
                                `Lot ${columnIndex}: ${lotName}`
                            )
                        }
                    </div>
                    <div className={
                        classNames(
                            layoutStyles.flex,
                            layoutStyles.row,
                            layoutStyles.borderRight,
                            layoutStyles.borderBottom,
                            styles.headingFont,
                            styles.whiteBackground
                        )
                    }>
                        <div
                            className={
                                classNames(
                                    layoutStyles.flex,
                                    layoutStyles.col,
                                    layoutStyles.flex1,
                                    styles.headingPad,
                                    'assignmentCheck'
                                )
                            }
                        >
                            <div
                                style={{
                                    marginBottom: 5
                                }}
                                className={
                                    classNames(
                                        layoutStyles.contentCenter,
                                        layoutStyles.flex,
                                    )
                                }
                            >
                                Assign
                            </div>
                            <div
                                className={
                                    classNames(
                                        layoutStyles.flex,
                                        layoutStyles.contentCenter,
                                        layoutStyles.itemsCenter
                                    )
                                }
                            >
                                <Checkbox
                                    id={'Lot' + columnIndex + '-InviteAll'}
                                    icon={<EmptyCheck/>}
                                    className={classes.backgroundColorOnWholeIcon}
                                    disabled={isDisabled || (isLive && eventType === 'auction')}
                                    onClick={e => assignAll(e, columnIndex - 1)}
                                    checked={areAllAssigned(lot.suppliers)}
                                />
                            </div>
                        </div>
                        {
                            takePrice && (
                                <div 
                                    style={{
                                        width: 139,
                                        height: 49
                                    }}
                                    className={
                                        classNames(
                                            layoutStyles.flex,
                                            layoutStyles.contentCenter,
                                            layoutStyles.itemsCenter,
                                            layoutStyles.borderLeft
                                        )
                                    }
                                >
                                    {
                                        (eventType === 'auction'
                                        && auctionType === 'b')
                                        || sourcingType === 'sell'
                                        ? 'Set Floor Price'
                                        : 'Set Ceiling Price'
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>
            )
        }
        const { suppliers: assignedSuppliers } = lot;
        const invitedSupplier = suppliers[rowIndex - 1];
        const supplierId = invitedSupplier.id;
        const supplier = assignedSuppliers && assignedSuppliers[invitedSupplier.id];
        const isChecked = !!supplier;
        const isPriceEditable = !isDisabled && isChecked;
        return (
            <div
                style={style}
                key={key}
                className={
                    classNames(
                        styles.checkWrap,
                        layoutStyles.borderRight,
                        layoutStyles.borderBottom,
                    )
                }
            >
                <div className={
                    classNames(
                        "assignmentCheck"
                    )
                }>
                    <Checkbox
                        id={'Lot' + columnIndex + '-Supplier' + rowIndex + '-Invite'}
                        icon={<EmptyCheck/>}
                        className={classes.backgroundColorOnWholeIcon}
                        checked={isChecked}
                        disabled={isDisabled || (isLive && invitedSupplier.status === 'approved' && eventType === 'auction')}
                        onClick={e => assignOne(e, columnIndex - 1, invitedSupplier)}
                    />
                </div>
                {
                    takePrice && (
                        <div
                            className={
                                classNames(
                                    layoutStyles.flex,
                                    layoutStyles.contentCenter,
                                    layoutStyles.itemsCenter,
                                    layoutStyles.flex1,
                                    layoutStyles.borderLeft,
                                    {
                                        'editable': isPriceEditable
                                    }
                                )
                            }
                        >
                            <span
                                style={{
                                    wordBreak: 'keep-all'
                                }}
                            >
                                {data && data.currency}
                            </span>
                            <span
                                id={'Lot' + columnIndex + '-Supplier' + rowIndex + '-Price'}
                                data-supplier={invitedSupplier.company_name}
                                data-lot={lotName}
                                style={{
                                    width: '80%',
                                    textAlign: 'right'
                                }}
                                contentEditable={isPriceEditable}
                                onBlur={e => updatePrice(e, supplier, columnIndex - 1)}
                                dangerouslySetInnerHTML={{
                                    __html: (
                                        lot.suppliers
                                        && lot.suppliers[supplierId]
                                        && lot.suppliers[supplierId].ceiling_price
                                    )
                                }}
                                onPaste={e => onPriceLimitPaste(e, rowIndex - 1, columnIndex - 1)}
                            >
                            </span>
                        </div>
                    )
                }
            </div>
        )
    };

    const getRowHeight = ({ index }) => {
        if (index === 0) {
            return 77;
        }
        return 28;
    };

    const getColumnWidth = ({ index }) => {
        if (index === 0) {
            return 250;
        }
        return 216;
    };

    const totalLots = lots ? lots.length : 0;
    const totalSuppliers = suppliers ? suppliers.length : 0;
    const headerHeight = 77;
    const visibleSuppliers = Math.min(totalSuppliers, 15);
    const contentHeight = visibleSuppliers * 28 + scrollbarSize();

    if (!suppliers.length || !lots.length) {
        return null;
    }

    return (
        <ThemeProvider theme={theme}>
            <div
                className={classNames(styles.container)}
            >
                <AutoSizer disableHeight>
                    {
                        ({
                            width,
                            height
                        }) => {
                            return (
                                <div>
                                    <MultiGrid
                                        cellRenderer={_cellRenderer}
                                        columnWidth={getColumnWidth}
                                        columnCount={totalLots + 1}
                                        height={headerHeight + contentHeight}
                                        width={width}
                                        rowHeight={getRowHeight}
                                        rowCount={totalSuppliers + 1}
                                        overscanColumnCount={4}
                                        overscanRowCount={4}
                                        fixedColumnCount={1}
                                        fixedRowCount={1}
                                        ref={setGridRef}
                                    />
                                </div>
                            )
                        }
                    }
                </AutoSizer>
            </div>
        </ThemeProvider>
    )
};

export default SupplierAssignment;
